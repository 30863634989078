<template>
	<div class="staff-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
		<b-modal v-model="modal" centered hide-header-close
		         ok-variant="danger"
		         cancel-variant="custom-light"
		         v-bind:ok-disabled="!password || !staffNo"
		         v-on:ok="deleteStaff"
		         v-on:hide="resetModal">
			<h3 slot="modal-title">Delete Staff</h3>
			<h5 slot="modal-ok" class="m-0">Confirm</h5>
			<h5 slot="modal-cancel" class="m-0">Cancel</h5>
			<form action="" class="container-modal-form position-relative w-100 h-100 p-3">
				<div class="form-group position-relative">
					<label for="password" class="font-weight-bold">Password</label>
					<input type="password" id="password" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': pwInvalid}"
					       v-model="password"
					       v-on:input="pwInvalid = false">
				</div>
				<div class="form-group position-relative">
					<label for="modalStaffNo" class="font-weight-bold">Staff No.</label>
					<input type="text" id="modalStaffNo" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': staffNoInvalid}"
					       v-model="staffNo"
					       v-on:input="staffNoInvalid = false">
				</div>
			</form>
		</b-modal>
		<div class="details position-relative w-75 h-100 rounded bg-white d-flex flex-column align-items-center overflow-hidden">
			<form-controls v-bind:msg="msg"
			               v-bind:msg-invalid="msgInvalid">
				<control-button slot="left"
				                v-bind:disabled="false"
				                v-bind:btn-title="backBtnTitle"
				                v-bind:btn-icon="['fas', 'arrow-left']"
				                v-bind:btn-class="backBtnClass"
				                v-on:btn-pressed="goBack"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!savable"
				                btn-title="SAVE"
				                v-bind:btn-icon="['fas', 'hdd']"
				                btn-class="btn-custom-light ml-2"
				                v-on:btn-pressed="saveStaff"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!staff"
				                btn-title="DELETE"
				                v-bind:btn-icon="['fas', 'trash-alt']"
				                btn-class="btn-danger ml-2"
				                v-on:btn-pressed="modal = true"></control-button>
			</form-controls>
			<form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
				<div class="container-avatar position-relative w-100 mb-3 text-center">
					<img v-bind:src="localStaff.avatar" alt="Avatar" class="avatar position-relative rounded-circle"
					     v-bind:class="{'male-border': localStaff.gender === 'M', 'female-border': localStaff.gender === 'F'}"
					     v-on:error="imageError">
				</div>
				<div class="form-group position-relative w-100">
					<label for="staffNo" class="font-weight-bold">Staff No.</label>
					<input type="text" id="staffNo" class="form-control position-relative" placeholder=""
					       v-bind:value="localStaff.staffNo" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="avatar" class="font-weight-bold">Profile Picture</label>
					<input type="text" id="avatar" placeholder="Profile Picture" class="form-control position-relative"
					       v-bind:class="invalidInputs.avatar"
					       v-bind:value="localStaff.avatar"
					       v-on:input="updateStaff('avatar', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="lastName" class="font-weight-bold">Last Name</label>
					<input type="text" id="lastName" placeholder="Last Name" class="form-control position-relative"
					       v-bind:class="invalidInputs.lastName"
					       v-bind:value="localStaff.lastName"
					       v-on:input="updateStaff('lastName', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="firstName" class="font-weight-bold">First Name</label>
					<input type="text" id="firstName" placeholder="First Name" class="form-control position-relative"
					       v-bind:class="invalidInputs.firstName"
					       v-bind:value="localStaff.firstName"
					       v-on:input="updateStaff('firstName', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="preferredName" class="font-weight-bold">Preferred Name</label>
					<input type="text" id="preferredName" placeholder="Preferred Name" class="form-control position-relative"
					       v-bind:class="invalidInputs.preferredName"
					       v-bind:value="localStaff.preferredName"
					       v-on:input="updateStaff('preferredName', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="gender" class="font-weight-bold">Gender</label>
					<select id="gender" class="form-control position-relative"
					        v-bind:class="invalidInputs.gender"
					        v-bind:value="localStaff.gender"
					        v-on:change="updateStaff('gender', $event.target.value)">
						<option disabled hidden value=""></option>
						<option>M</option>
						<option>F</option>
					</select>
				</div>
				<div class="form-group position-relative w-100">
					<label for="dob" class="font-weight-bold text-truncate">Date Of Birth</label>
					<b-form-datepicker id="dob" placeholder="Date"
					                   class="date-picker"
					                   v-bind:class="invalidInputs.DOB"
					                   v-bind:value="localStaff.DOB.split('T')[0]"
					                   v-on:input="updateDOB($event)">
					</b-form-datepicker>
				</div>
				<div class="form-group position-relative w-100">
					<label for="phone" class="font-weight-bold">Phone Number</label>
					<input type="text" id="phone" placeholder="Phone Number" class="form-control position-relative"
					       v-bind:class="invalidInputs.phone"
					       v-bind:value="localStaff.phone"
					       v-on:input="updateStaff('phone', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="email" class="font-weight-bold">Email</label>
					<input type="text" id="email" placeholder="Email" class="form-control position-relative"
					       v-bind:class="invalidInputs.email"
					       v-bind:value="localStaff.email"
					       v-on:input="updateStaff('email', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="address" class="font-weight-bold">Address</label>
					<input type="text" id="address" placeholder="Address" class="form-control position-relative"
					       v-bind:class="invalidInputs.address"
					       v-bind:value="localStaff.address"
					       v-on:input="updateStaff('address', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="department" class="font-weight-bold">Department</label>
					<input type="text" id="department" placeholder="Department" class="form-control position-relative"
					       v-bind:class="invalidInputs.department"
					       v-bind:value="localStaff.department"
					       v-on:change="updateStaff('department', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="position" class="font-weight-bold">Position</label>
					<input type="text" id="position" placeholder="Position" class="form-control position-relative"
					       v-bind:class="invalidInputs.position"
					       v-bind:value="localStaff.position"
					       v-on:change="updateStaff('position', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="salary" class="font-weight-bold">Salary</label>
					<input type="text" id="salary" placeholder="Salary" class="form-control position-relative"
					       v-bind:class="invalidInputs.salary"
					       v-bind:value="localStaff.salary"
					       v-on:change="updateStaff('salary', $event.target.value)">
				</div>
				<div class="form-group position-relative w-100">
					<label for="notes" class="font-weight-bold">Notes</label>
					<textarea id="notes" rows="6" class="form-control position-relative"
					          v-model="localStaff.notes"></textarea>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import isNumeric from "validator/lib/isNumeric";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import isURL from "validator/lib/isURL";
import {BFormDatepicker, BModal} from "bootstrap-vue";
import { dateTimeDifferent } from "@/utility/helpers";

export default {
	name: "StaffDetails",
	
	components: {
		FormControls,
		ControlButton,
		BFormDatepicker,
		BModal
	},
	
	props: {
		// props from vue router route params
		staffId: {
			type: String,
		}
	},
	
	beforeRouteLeave(to, from, next) {
		this.modal = false;
		if (this.savable) {
			if (this.msg === "Leave Without Saving?") {
				// user has already been notified
				next();
			} else {
				this.toRoute = to.fullPath;
				this.msg = "Leave Without Saving?";
				this.msgInvalid = true;
				this.backBtnTitle = "YES!";
				this.backBtnClass = "btn-danger mr-2";
			}
		} else {
			next();
		}
	},
	
	created() {
		this.initStaff();
		window.addEventListener("beforeunload", this.unloadHandler);
	},
	
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.unloadHandler);
	},
	
	data() {
		return {
			localStaff: {
				_id: "",
				staffNo: "",
				firstName: "",
				lastName: "",
				preferredName: "",
				gender: "",
				DOB: "",
				phone: "",
				email: "",
				address: "",
				salary: "",
				department: "",
				position: "",
				avatar: "",
				notes: "",
			},
			invalidInputs: {
				firstName: "",
				lastName: "",
				preferredName: "",
				gender: "",
				DOB: "",
				phone: "",
				email: "",
				address: "",
				salary: "",
				department: "",
				position: "",
				// can be invalid but not required
				avatar: "",
			},
			msg: "",
			msgInvalid: false,
			modal: false,
			toRoute: "",
			backBtnTitle: "BACK",
			backBtnClass: "btn-custom-light mr-2",
			password: "",
			pwInvalid: false,
			staffNo: "",
			staffNoInvalid: false,
		}
	},
	
	computed: {
		staff() {
			return this.$store.state.contents.staffs.find(({ _id }) => _id === this.staffId);
		},
		
		savable() {
			// fall through pattern
			if (!this.staff) return true;
			// can safely access properties inside this.staff due to the first if clause in this function
			// though we need not care about time zone for DOB but using dateTimeDifferent for this check is fine
			// as long as we update the DOB string properly: time portion must be all 0s
			if (dateTimeDifferent(this.staff.DOB, this.localStaff.DOB)) return true;
			const keys = ["firstName", "lastName", "preferredName", "gender", "phone", "email", "address",
				"salary", "department", "position", "avatar", "notes"];
			return keys.some(key => this.localStaff[key] !== this.staff[key]);
		},
	},
	
	methods: {
		unloadHandler(event) {
			if (this.savable) {
				event.preventDefault();
				event.returnValue = '';
			}
		},
		
		resetModal(bvModalEvent) {
			if (bvModalEvent.trigger !== "ok") {
				this.password = "";
				this.pwInvalid = false;
				this.staffNo = "";
				this.staffNoInvalid = false;
			}
		},
		
		imageError(e) {
			e.target.src = require("../../../assets/media/user_backup.png");
		},
		
		initStaff() {
			if (this.staff) {
				this.localStaff = {...this.staff};
			} else {
				this.localStaff._id = uuidv4();
				this.localStaff.staffNo = "STA-" + this.localStaff._id.split("-")[0].toUpperCase();
			}
		},
		
		updateDOB(value) {
			this.localStaff.DOB = `${value}T00:00:00.000Z`;
			this.invalidInputs.DOB = "";
		},
		
		updateStaff(key, value) {
			this.localStaff[key] = value;
			switch (key) {
				// avatar is not a required field
				case "avatar":
					if (value.trim().length > 0) {
						this.invalidInputs[key] = isURL(value) ? "" : "invalid";
					} else {
						this.invalidInputs[key] = "";
					}
					break;
				case "phone":
					this.invalidInputs[key] = isMobilePhone(value) ? "" : "invalid";
					break;
				case "email":
					this.invalidInputs[key] = isEmail(value) ? "" : "invalid";
					break;
				case "salary":
					// nullish check is included
					if (isNumeric(value) && parseFloat(value) >= 0) {
						// bind to change event instead of input event
						this.localStaff[key] = parseFloat(value).toFixed(2);
						this.invalidInputs[key] = "";
					} else {
						this.invalidInputs[key] = "invalid";
					}
					break;
				default:
					// check if value is just white spaces
					this.invalidInputs[key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
		},
		
		// only set invalid, shouldn't set it back to empty string
		// nullish check only, no other validations since they are being taken care of in input event handlers
		dataInvalid() {
			const keys = Object.keys(this.invalidInputs);
			keys.forEach(key => {
				// avatar can be null or empty or white space, it's not required
				if (key !== "avatar" && !this.localStaff[key] ||
					this.localStaff[key].trim().length === 0) this.invalidInputs[key] = "invalid";
			});
			return keys.some(key => this.invalidInputs[key] === "invalid");
		},
		
		async saveStaff() {
			this.backBtnTitle = "BACK";
			this.backBtnClass = "btn-custom-light mr-2";
			if (this.dataInvalid()) {
				this.msg = "Please Fix Invalid Fields!";
				this.msgInvalid = true;
			} else {
				// need not do deep copy for contacts since initStaff will be run upon successful saving
				const staff = {...this.localStaff};
				await this.$store.dispatch("contents/updateContent", { key: "staffs", value: staff});
				if (this.staffId) {
					this.initStaff();
					this.msg = "Previous Changes Saved!";
					this.msgInvalid = false;
				} else {
					// bypassing beforeRouteLeave guard
					this.msg = "Leave Without Saving?";
					await this.$router.push(`/project/staff/${staff._id}`);
					// need to run initStaff after route change since staffId prop was undefined
					this.initStaff();
					// vue router reuses this component
					this.msgInvalid = false;
					this.msg = "New Staff Created!";
				}
			}
		},
		
		async deleteStaff(bvModalEvent) {
			if (this.password !== this.$store.state.user.user.password) {
				bvModalEvent.preventDefault();
				this.pwInvalid = true;
				if (this.staffNo !== this.localStaff.staffNo) this.staffNoInvalid = true;
			} else if (this.staffNo !== this.localStaff.staffNo) {
				this.staffNoInvalid = true;
				bvModalEvent.preventDefault();
			} else {
				await this.$store.dispatch("contents/deleteContent", { key: "staffs", _id: this.staff._id});
				// bypassing beforeRouteLeave guard
				this.msg = "Leave Without Saving?";
				await this.$router.push("/project/staff");
			}
		},
		
		async goBack() {
			if (this.msg === "Leave Without Saving?") {
				await this.$router.push(this.toRoute);
			} else {
				await this.$router.push("/project/staff");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
.avatar {
	width: 120px;
	height: 120px;
	min-width: 120px;
	min-height: 120px;
	object-fit: cover;
	border: 5px solid rgba(0,0,0,.3);
	transition: all .2s ease;
	
	&.male-border {
		border: 5px solid rgba(107,164,249,.6);
	}
	
	&.female-border {
		border: 5px solid rgba(255, 100, 128, .6);
	}
}
</style>